import service from "../config/request";
import baseUrl from "../config/baseUrl";
//请求根路径（ps：就是所有请求中，地址中前面不变的那一部分）


//首页
export function login(params) {
    return service({
        //请求路径
        url: `${baseUrl}/api/zx/login`,
        //请求方法
        methods: 'get',
        //数据（ps：此处使用了es6解构的语法，原写法为  params: params）
        params
    })
}

//首页
export function index(params) {
    return service({
        //请求路径
        url: `${baseUrl}/api/capacity/index`,
        //请求方法
        methods: 'get',
        //数据（ps：此处使用了es6解构的语法，原写法为  params: params）
        params
    })
}

//私域流量渠道销售额统计
export function Channel(params) {
    return service({
        //请求路径
        url: `${baseUrl}/api/zx/Channel`,
        //请求方法
        methods: 'get',
        //数据（ps：此处使用了es6解构的语法，原写法为  params: params）
        params
    })
}

//渠道销售额统计
export function apply(params) {
    return service({
        //请求路径
        url: `${baseUrl}/api/zx/apply`,
        //请求方法
        methods: 'get',
        //数据（ps：此处使用了es6解构的语法，原写法为  params: params）
        params
    })
}

//私域流量渠道用户新增统
export function user(params) {
    return service({
        //请求路径
        url: `${baseUrl}/api/zx/user`,
        //请求方法
        methods: 'get',
        //数据（ps：此处使用了es6解构的语法，原写法为  params: params）
        params
    })
}


//总渠道新增用户--总渠道GMV
export function getUserSum(params) {
    return service({
        //请求路径
        url: `${baseUrl}/api/zx/getUserSum`,
        //请求方法
        methods: 'get',
        //数据（ps：此处使用了es6解构的语法，原写法为  params: params）
        params
    })
}

//渠道数据统计
export function getChannelList(params) {
    return service({
        //请求路径
        url: `${baseUrl}/api/zx/getChannelList`,
        //请求方法
        methods: 'get',
        //数据（ps：此处使用了es6解构的语法，原写法为  params: params）
        params
    })
}


//今日订单统计
export function getOrderLits(params) {
    return service({
        //请求路径
        url: `${baseUrl}/api/zx/getOrderLits`,
        //请求方法
        methods: 'get',
        //数据（ps：此处使用了es6解构的语法，原写法为  params: params）
        params
    })
}


//今日拼团总额
export function pingAmountTotal(params) {
    return service({
        //请求路径
        url: `${baseUrl}/api/capacity/pingAmountTotal`,
        //请求方法
        methods: 'get',
        //数据（ps：此处使用了es6解构的语法，原写法为  params: params）
        params
    })
}

//拼团金额统计
export function pingAmountStatistics(params) {
    return service({
        //请求路径
        url: `${baseUrl}/api/capacity/pingAmountStatistics`,
        //请求方法
        methods: 'get',
        //数据（ps：此处使用了es6解构的语法，原写法为  params: params）
        params
    })
}

//拼团情况（近10天）
export function pingAmountList(params) {
    return service({
        //请求路径
        url: `${baseUrl}/api/capacity/pingAmountList`,
        //请求方法
        methods: 'get',
        //数据（ps：此处使用了es6解构的语法，原写法为  params: params）
        params
    })
}

//合成情况
export function compositeList(params) {
    return service({
        //请求路径
        url: `${baseUrl}/api/capacity/compositeList`,
        //请求方法
        methods: 'get',
        //数据（ps：此处使用了es6解构的语法，原写法为  params: params）
        params
    })
}

//出货/提货总额
export function goodsAmoutTotal(params) {
    return service({
        //请求路径
        url: `${baseUrl}/api/capacity/goodsAmoutTotal`,
        //请求方法
        methods: 'get',
        //数据（ps：此处使用了es6解构的语法，原写法为  params: params）
        params
    })
}

//出货/提货金额统计
export function goodsAmoutStatistics(params) {
    return service({
        //请求路径
        url: `${baseUrl}/api/capacity/goodsAmoutStatistics`,
        //请求方法
        methods: 'get',
        //数据（ps：此处使用了es6解构的语法，原写法为  params: params）
        params
    })
}

//私域流量渠道销售额统计
export function userGoodsTopList(params) {
    return service({
        //请求路径
        url: `${baseUrl}/api/capacity/userGoodsTopList`,
        //请求方法
        methods: 'get',
        //数据（ps：此处使用了es6解构的语法，原写法为  params: params）
        params
    })
}

//能量树
export function energyTree(params) {
    return service({
        //请求路径
        url: `${baseUrl}/api/capacity/energyTree`,
        //请求方法
        methods: 'get',
        //数据（ps：此处使用了es6解构的语法，原写法为  params: params）
        params
    })
}






//获取全部商户GMV统计数据
export function getAllGMVList(params) {
    return service({
        //请求路径
        url: `${baseUrl}/api/lineChart`,
        //请求方法
        methods: 'get',
        //数据（ps：此处使用了es6解构的语法，原写法为  params: params）
        params
    })
}
//获取最佳商户排行
export function getRanking(params) {
    return service({
        //请求路径
        url: `${baseUrl}/api/index`,
        //请求方法
        methods: 'get',
        //数据（ps：此处使用了es6解构的语法，原写法为  params: params）
        params
    })
}
//获取今日GMV
export function getTodayGMV(params) {
    return service({
        //请求路径
        url: `${baseUrl}/api/dayStatistics`,
        //请求方法
        methods: 'get',
        //数据（ps：此处使用了es6解构的语法，原写法为  params: params）
        params
    })
}
//获取签约商户信息signContract
export function getSignContract(params) {
    return service({
        //请求路径
        url: `${baseUrl}/api/signContract`,
        //请求方法
        methods: 'get',
        //数据（ps：此处使用了es6解构的语法，原写法为  params: params）
        params
    })
}
//获取在售商品
export function onlineSaleGoods(params) {
    return service({
        //请求路径
        url: `${baseUrl}/api/onlineSaleGoods`,
        //请求方法
        methods: 'get',
        //数据（ps：此处使用了es6解构的语法，原写法为  params: params）
        params
    })
}
//获取在售销售
export function onlineSale(params) {
    return service({
        //请求路径
        url: `${baseUrl}/api/onlineSale`,
        //请求方法
        methods: 'get',
        //数据（ps：此处使用了es6解构的语法，原写法为  params: params）
        params
    })
}
//获取签约商户柱状图
export function signShopContract(params) {
    return service({
        //请求路径
        url: `${baseUrl}/api/signShopContract`,
        //请求方法
        methods: 'get',
        //数据（ps：此处使用了es6解构的语法，原写法为  params: params）
        params
    })
}